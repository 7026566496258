import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const getAssignments = createAsyncThunk(
  "/assignments/get",
  async ({ acadyear }, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const formData = new FormData();
      const { sem, branch, reg_no } = getState().auth.user;
      formData.append("sem", sem);
      formData.append("branch", branch);
      formData.append("acadyear", acadyear);
      formData.append("regno", reg_no);
      const response = await axios({
        url: process.env.REACT_APP_API_URL + "/assignments.php",
        method: "POST",
        data: formData,
      });
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue({ msg: "something went wrong !" });
    }
  }
);

export const getPendingAssignments = createAsyncThunk(
  "/assignments/pendingCount",
  async ({ acadyear }, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const formData = new FormData();
      const { sem, branch, reg_no } = getState().auth.user;
      formData.append("sem", sem);
      formData.append("branch", branch.toLowerCase());
      formData.append("acadyear", acadyear);
      formData.append("regno", reg_no);
      const response = await axios({
        url: process.env.REACT_APP_API_URL + "/getAssignmentStatus.php",
        method: "POST",
        data: formData,
      });
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue({ msg: "something went wrong !" });
    }
  }
);

export const uploadAssignment = createAsyncThunk(
  "/assignments/post",
  async (
    { acadyear, subcode, unitno, myfile, cid, sid },
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    try {
      const formData = new FormData();
      const { sem, branch, reg_no, name } = getState().auth.user;
      formData.append("sem", sem);
      formData.append("branch", branch.toLowerCase());
      formData.append("acadyear", acadyear);
      formData.append("regno", reg_no);
      formData.append("subcode", subcode);
      formData.append("unitno", unitno);
      formData.append("myfile", myfile);
      formData.append("name", name);
      formData.append("cid", cid);
      formData.append("sid", sid);
      formData.set("myfile", myfile);
      const response = await axios({
        url: process.env.REACT_APP_API_URL + "uploadAssignment.php",
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue({ msg: "something went wrong !" });
    }
  }
);

export const AssignmentAdapter = createEntityAdapter({
  selectId: (assignment) => assignment.cid,
});

export const AssignmentsSlice = createSlice({
  name: "assignments",
  reducers: {
    setIntialAssignment: (state) => {
      AssignmentAdapter.setAll(state, []);
      state.filtered = [];
    },
    sortByStatus: (state, action) => {
      if (action.payload.toUpperCase() === "ALL")
        state.filtered = state.entities;
      else if (action.payload.toUpperCase() === "NOT UPLOAD")
        state.filtered = Object.values(state.entities).filter(
          (ut) => ut.status === "NOTUPLOAD"
        );
      else if (action.payload.toUpperCase() === "PENDING")
        state.filtered = Object.values(state.entities).filter(
          (ut) => ut.status === "PENDING"
        );
      else if (action.payload.toUpperCase() === "APPROVED")
        state.filtered = Object.values(state.entities).filter(
          (ut) => ut.status === "APPROVED"
        );
      else if (action.payload.toUpperCase() === "DENIED")
        state.filtered = Object.values(state.entities).filter(
          (ut) => ut.status === "DENIED"
        );
    },
  },
  initialState: AssignmentAdapter.getInitialState({
    pending: false,
    error: null,
    filtered: [],
    count: 0,
  }),
  extraReducers: {
    [getAssignments.fulfilled]: (state, action) => {
      AssignmentAdapter.addMany(state, action.payload);
      state.filtered = state.entities;
      state.pending = false;
    },
    [getAssignments.pending]: (state) => {
      state.pending = true;
    },
    [getAssignments.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getPendingAssignments.fulfilled]: (state, action) => {
      state.count = action.payload.pending;
      state.pending = false;
    },
    [getPendingAssignments.pending]: (state) => {
      state.pending = true;
    },
    [getPendingAssignments.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [uploadAssignment.fulfilled]: (state, action) => {
      const data = action.payload.data;
      AssignmentAdapter.updateOne(state, {
        id: data.cid,
        changes: { status: data.status },
      });
      state.filtered = state.entities;
      toast.update("fileProcessing", {
        render: "File upload successfully",
        autoClose: true,
        type: "success",
        position: "top-center",
      });
    },
    [uploadAssignment.pending]: () => {
      toast("File processing...", {
        autoClose: false,
        toastId: "fileProcessing",
        updateId: "fileProcessing",
        position: "top-center",
      });
    },
    [uploadAssignment.rejected]: () => {
      toast.update("fileProcessing", {
        render: "Something went wrong!",
        autoClose: true,
        type: "error",
        position: "top-center",
      });
    },
  },
});

export const { getOptions, sortByStatus, setIntialAssignment } =
  AssignmentsSlice.actions;
