import React, { useEffect } from "react";
import Card from "./Card";
import { getCirculars } from "../../store/circulars.slice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../utils/Loader";

const Circular = () => {
  const circulars = useSelector((state) => state.circulars.data);
  const isPending = useSelector((state) => state.circulars.pending);

  const dispatch = useDispatch();
  useEffect(() => {
    async function intializeData() {
      await dispatch(getCirculars({ acadyear: "2021" }));
    }
    intializeData();
  }, []);

  return (
    <div className="px-primaryMob">
      {/* <div>
        <JBPSelect options={["All", "Pending", "Approved", "Denied"]} />
      </div> */}
      {isPending && (
        <div className="flex justify-center items-center py-16">
          <Loader />
        </div>
      )}
      <div
        className={`pb-16 md:py-10  md:grid  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4`}
      >
        {Array.isArray(circulars) && circulars?.map((circular, index) => {
          return <Card {...{ circular }} key={index} />;
        })}
      </div>
    </div>
  );
};

export default Circular;
