import React from "react";
import { useSelector } from "react-redux";

const Card = () => {
  const user = useSelector((state) => state.auth.user);
  return (
    <div>
      <div
        className={`w-full py-primaryMob px-4 shadow-lg flex flex-col items-center hover:scale-110 transition-all ease-in-out hover:bg-primary hover:text-white border-black rounded-md hover:border-white`}
      >
        <img
          src="https://pickaface.net/gallery/avatar/unr_handsomeboy_180407_1616_z233f.png"
          alt=""
          className="w-[100px] rounded-full border-2 "
        />
        <div className="px-primaryMob py-3 flex flex-col ">
          <span className=" text-center">{user.name}</span>
          <span className="text-md text-center">{user.branch}</span>
          <span className="text-md text-center">{user.sem} sem</span>
        </div>
      </div>
    </div>
  );
};

export default Card;
