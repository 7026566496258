import React, { useEffect } from "react";
import JBPSelect from "../utils/JBPSelect";
import JBPCard from "../utils/JBPCard";
import { useDispatch, useSelector } from "react-redux";
import { getOptions, getUT, sortBySubCode } from "../../store/ut.slice";
import { motion } from "framer-motion";
import Loader from "../utils/Loader";

function UnitTests() {
  const notesData = useSelector((state) => state.ut.filtered);
  const isPending = useSelector((state) => state.ut.pending);
  const options = useSelector((state) => state.ut.options);

  const dispatch = useDispatch();

  useEffect(() => {
    async function intializeData() {
      await dispatch(getUT({ sem: 1, branch: "at", acadyear: "2021" }));
      dispatch(getOptions());
    }
    intializeData();
  }, []);

  function sort(filterValue) {
    dispatch(sortBySubCode(filterValue));
  }

  return (
    <div className="px-primaryMob">
      <div>
        <JBPSelect
          options={["All", ...options]}
          onChange={(e) => sort(e.target.value)}
        />
      </div>
      {isPending && (
        <div className="flex justify-center items-center py-16">
          <Loader />
        </div>
      )}
      <motion.div
        layout
        className="pb-16 md:py-10 h-fit  md:grid  md:grid-cols-2 lg:grid-cols-3 gap-4"
      >
        {notesData.map((note, index) => {
          return <JBPCard {...{ note }} key={note.subcode + index} />;
        })}
      </motion.div>
    </div>
  );
}

export default UnitTests;
