import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getRemarks = createAsyncThunk(
  "/remark/get",
  async ({ acadyear }, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const formData = new FormData();
      const { sem, branch, reg_no } = getState().auth.user;
      formData.append("sem", sem);
      formData.append("branch", branch);
      formData.append("acadyear", acadyear);
      formData.append("regno", reg_no);
      const response = await axios({
        url: "http://localhost/public_html/kspolytechnic/api/remarks.php",
        method: "POST",
        data: formData,
      });
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue({ msg: "something went wrong !" });
    }
  }
);

export const RemarksSlice = createSlice({
  name: "remark",
  reducers: {},
  initialState: {
    data: [],
    error: null,
    pending: false,
  },
  extraReducers: {
    [getRemarks.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.pending = false;
    },
    [getRemarks.pending]: (state) => {
      state.pending = true;
    },
  },
});
