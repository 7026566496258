import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { setIntialAssignment } from "./assignments.slice";

export const login = createAsyncThunk(
  "/auth/login",
  async (
    { sem, branch, acadyear, regno, phone },
    { fulfillWithValue, rejectWithValue }
  ) => {
    var data;
    try {
      const formData = new FormData();
      formData.append("sem", sem);
      formData.append("branch", branch);
      formData.append("acadyear", acadyear);
      formData.append("regno", regno);
      formData.append("phone_no", phone);
      const response = await axios({
        url: process.env.REACT_APP_API_URL + "login.php",
        method: "POST",
        data: formData,
      });
      data = response.data;
      return fulfillWithValue(data);
    } catch (error) {
      console.log(error);
      return rejectWithValue({ msg: error.response.data?.msg });
    }
  }
);

export const logout = createAsyncThunk(
  "/auth/logout",
  async (_payload, { fulfillWithValue, dispatch }) => {
    dispatch(setIntialAssignment());
    dispatch(setLogoutUser());
  }
);

export const AuthSlice = createSlice({
  name: "auth",
  reducers: {
    setLogoutUser: (state) => {
      state.user = null;
    },
  },
  initialState: { user: null, error: null, pending: false },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.error = null;
      state.user = action.payload;
      state.pending = false;
      toast.update("authProcessing", {
        render: "Hey👋 " + state.user.name,
        autoClose: true,
        type: "success",
        position: "top-center",
        isLoading: false,
      });
    },
    [login.pending]: (state) => {
      state.pending = true;
      toast("Logging in...", {
        autoClose: false,
        toastId: "authProcessing",
        position: "top-center",
        isLoading: true,
      });
    },
    [login.rejected]: (state, action) => {
      state.error = action.payload.msg;
      state.pending = false;
      toast.update("authProcessing", {
        render: action.payload.msg,
        autoClose: true,
        type: "error",
        position: "top-center",
        hideProgressBar: true,
        isLoading: false,
      });
    },
  },
});

const { setLogoutUser } = AuthSlice.actions;
