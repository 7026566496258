import React from "react";

function Selector({ title, options, onChange, value, className }) {
  return (
    <div className={"flex flex-col lg:ml-5 lg:first:ml-0 md:ml-0" + className}>
      <label className="flex flex-col text-md font-bold">{title}</label>
      <select
        {...{ onChange }}
        {...{ value }}
        className="w-fit bg-transparent active:shadow-none active:scale-95 text-md py-2 px-2 text-textSecondary outline-primary border-b-2 border-gray-200"
      >
        {options.map((optionName, index) => {
          return (
            <option value={optionName} key={index}>
              {optionName}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default Selector;
