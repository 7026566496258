import React from "react";
import { useNavigate, useParams } from "react-router-dom";

function CircularModal() {
  const { filename } = useParams();
  return (
    <div
      id="cmodal"
      className="fixed top-0 left-0 z-50 backdrop-blur-sm w-full h-full flex justify-center items-center"
    >
      <iframe
        title={filename}
        src={"https://jbportals.com/kspolytechnic/CIRCULARS2021/" + filename}
        type={"application/pdf"}
        className={"h-full w-full md:w-1/2 py-5 px-3 object-contain"}
      ></iframe>
    </div>
  );
}

export default CircularModal;
