import React, { useState } from "react";
import {
  AiOutlineMenu,
  AiOutlineClose,
  AiOutlineHome,
  AiOutlineBook,
  AiOutlinePercentage,
  AiOutlineClockCircle,
  AiOutlineFile,
  AiOutlineEdit,
  AiOutlineExperiment,
  AiOutlineAlert,
  AiOutlineStar,
  AiOutlineSmile,
  AiOutlineLogout,
} from "react-icons/ai";
import NavLink from "./NavLink";
import Profile from "../Main/Profile";
import Home from "../Home";
import Attendence from "../Attendence";
import Marks from "../Marks";
import Notes from "../Notes";
import UnitTests from "../UnitTests";
import GR from "../GR";
import Assignment from "../Assignments";
import Circular from "../Circular/index.jsx";
import { useDispatch } from "react-redux";
import { logout } from "../../store/auth.slice";
import { useNavigate } from "react-router-dom";
import Remarks from "../Remarks";
import Schedule from "../Schedule";

export const Links = [
  {
    title: "Home",
    path: "/",
    NavIcon: ({ className }) => <AiOutlineHome className={className} />,
    Component: () => <Home />,
  },
  {
    title: "Attendance",
    path: "/attendance",
    NavIcon: ({ className }) => <AiOutlineBook className={className} />,
    Component: () => <Attendence />,
  },
  {
    title: "Marks",
    path: "/marks",
    NavIcon: ({ className }) => <AiOutlinePercentage className={className} />,
    Component: () => <Marks />,
  },
  {
    title: "Schedule",
    path: "/schedule",
    NavIcon: ({ className }) => <AiOutlineClockCircle className={className} />,
    Component: () => <Schedule />,
  },
  {
    title: "Notes",
    path: "/notes",
    NavIcon: ({ className }) => <AiOutlineFile className={className} />,
    Component: () => <Notes />,
  },
  {
    title: "Assignments",
    path: "/assignments",
    NavIcon: ({ className }) => <AiOutlineEdit className={className} />,
    Component: () => <Assignment />,
  },
  {
    title: "Unit Tests",
    path: "/unittests",
    NavIcon: ({ className }) => <AiOutlineExperiment className={className} />,
    Component: () => <UnitTests />,
  },
  {
    title: "Circulars",
    path: "/circular/*",
    NavIcon: ({ className }) => <AiOutlineAlert className={className} />,
    Component: () => <Circular />,
  },
  {
    title: "Remarks",
    path: "/remarks",
    NavIcon: ({ className }) => <AiOutlineStar className={className} />,
    Component: () => <Remarks />,
  },
  {
    title: "Grievance Redressal",
    path: "/feedback",
    NavIcon: ({ className }) => <AiOutlineSmile className={className} />,
    Component: () => <GR />,
  },
];

function Navbar() {
  const [Nav, setNav] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function handleNav() {
    setNav(!Nav);
  }

  function closeNav() {
    setNav(false);
  }

  function onLogout() {
    dispatch(logout());
    navigate("/login", { replace: true, state: null });
  }

  return (
    <nav
      className={`bg-white z-50 md:pt-[35px] fixed w-full flex flex-col top-0 left-0 md:w-[250px] md:h-[100vh] h-fit md:border-r md:static`}
    >
      <div
        className={`z-50 border-b md:border-b-0 flex items-center px-primaryMob w-full justify-between h-[55px] bg-bgPrimary ${
          !Nav && "drop-shadow-navShadowMob"
        } md:drop-shadow-none md:justify-center`}
      >
        {/* hamburger icon for mobile view */}
        <div onClick={handleNav} className={"md:hidden cursor-pointer"}>
          {!Nav ? (
            <AiOutlineMenu className="text-primary text-[24px]" />
          ) : (
            <AiOutlineClose className="text-primary text-[24px]" />
          )}
        </div>

        {/* logo */}
        <div className="py-3">
        <img src="/assets/iSmartLogo.png" width={120} height={120}/>
        </div>

        {/* avatar icon for mob view */}
        <div className="md:hidden">
          <Profile />
        </div>
      </div>

      {/* menu trail */}
      <ul
        className={`customScroll overflow-y-scroll overflow-x-hidden z-20 flex pt-[55px] md:pt-0 flex-col items-center px-primaryMob absolute md:sticky w-45% h-[100vh] ease-in-out duration-500 bg-bgPrimary md:translate-x-0 ${
          Nav ? "translate-x-[0%]" : "translate-x-[-100%]"
        }`}
      >
        {Links.map((link) => {
          return (
            <NavLink
              onClick={closeNav}
              key={link.path}
              path={link.path}
              title={link.title}
              NavIcon={link.NavIcon}
            />
          );
        })}
        {/* Logout button */}
        <button
          onClick={onLogout}
          className={
            "group flex items-center mt-[12px] md:mt-[20px] w-[190px] px-[26px] py-[11px] rounded-md cursor-pointer "
          }
        >
          <AiOutlineLogout className={"text-[24px]"} />
          <span className={"ml-[20px] text-[15px] font-bold"}>Logout</span>
        </button>

        <span className="text-xs mt-5 text-[#777777] py-10">
          Copyright © 2022 st.jbportals.com
        </span>
      </ul>
    </nav>
  );
}

export default Navbar;
