import React from "react";
import JBPSelect from "../utils/JBPSelect";
import DataTable from "../utils/DataTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSchedule } from "../../store/schedule.slice";
import { useState } from "react";
import Loader from "../utils/Loader";

const Schedule = () => {
  const dispatch = useDispatch();
  const [sem, setSem] = useState("1");
  const [test, setTest] = useState("WT1");
  const [acadyear, setAcadyear] = useState("odd2021");
  const schedule = useSelector((state) => state.schedule.data);
  const isPending = useSelector((state) => state.schedule.pending);
  const isError = useSelector((state) => state.schedule.error);

  useEffect(() => {
    dispatch(getSchedule({ test, acadyear, sem }));
  }, [sem, test, acadyear]);

  return (
    <div className="px-primaryMob pb-cardH">
      <div className="lg:flex flex-col md:flex-row w-full">
        <JBPSelect
          title={"Semester"}
          placeholder={"Select Sem"}
          options={["1", "2", "3", "4", "5", "6"]}
          onChange={(e) => setSem(e.target.value)}
          value={sem}
        />
        <JBPSelect
          title={"Exam/Test Type"}
          placeholder={"Select Type"}
          options={[
            "Writing Test 1",
            "Writing Test 2",
            "Writing Test 3",
            "Multiple Choice Questions",
            "Open Book Test",
            "Skill Test 1",
            "Skill Test 2",
            "Skill Test 3",
            "Internal Assessment 1",
            "Internal Assessment 2",
            "Internal Assessment 3",
            "Semester End Exam",
          ]}
          values={[
            "WT1",
            "WT2",
            "WT3",
            "MCQ",
            "OBT",
            "ST1",
            "ST2",
            "ST3",
            "IA1",
            "IA2",
            "IA3",
            "SEE",
          ]}
          onChange={(e) => setTest(e.target.value)}
          value={test}
        />
        <JBPSelect
          title={"Academic Year"}
          placeholder={"Select Year"}
          options={["ODD-2021", "EVEN-2021", "ODD-2022", "EVEN-2022"]}
          values={["odd2021", "even2021", "odd2022", "even2021"]}
          onChange={(e) => setAcadyear(e.target.value)}
          value={acadyear}
        />
      </div>
      {isPending && (
        <div className="p-primaryMob">
          <Loader />
        </div>
      )}
      {isError && (
        <div className="py-20 px-primaryMob w-full text-center md:text-left text-xl font-bold">
          {isError.msg} !
        </div>
      )}
      <div className="py-10">
        <DataTable data={schedule} />
      </div>
    </div>
  );
};

export default Schedule;
