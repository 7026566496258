import React from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { motion } from "framer-motion";
import JBButton from "../utils/JBButton";
import { Link } from "react-router-dom";

function Card({ circular }) {
  return (
    <motion.div
      layout
      animate={{ opacity: 1, y: 0, scale: 1 }}
      initial={{ opacity: 0, y: 20, scale: 0.7 }}
      transition={{ duration: 0.4, type: "spring" }}
      className="bg-white w-full h-full mt-4 pt-3 px-4 border rounded-md md:mt-0 text-sm shadow-lg border-primary"
    >
      <div className="flex justify-between">
        <b className="flex-1 whitespace-nowrap">Category</b>
        <span className="text-left flex-1">{circular.category}</span>
      </div>
      <div className="flex justify-between my-3">
        <b className="flex-1">Department</b>{" "}
        <span className="text-left flex-1">{circular.branch}</span>
      </div>
      <hr className="" />
      <div className="pt-2 flex justify-between px-2 items-center">
        <span className="text-textSecondary text-sm">{circular.date}</span>
        <a
          href={
            "http://jbportals.com/kspolytechnic/AT1assignmentS/" +
            circular.filename
          }
          download
          target={"_blank"}
          rel={"noreferrer"}
        >
          <AiOutlineDownload className="text-[24px] text-primary hover:scale-110 active:scale-95 cursor-pointer" />
        </a>
      </div>
      <div className={"py-3 px-2"}>
        <Link to={"/circular/" + circular.filename}>
          <JBButton full>{"View"}</JBButton>
        </Link>
      </div>
    </motion.div>
  );
}
export default Card;
