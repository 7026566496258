import React from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { motion } from "framer-motion";

function JBPCard({ note }) {
  return (
    <motion.div
      layout
      animate={{ opacity: 1, y: 0, scale: 1 }}
      initial={{ opacity: 0, y: 20, scale: 0.7 }}
      transition={{ duration: 0.4, type: "spring" }}
      className="bg-white w-full h-full mt-4 pt-3 px-4 border rounded-md md:mt-0 text-sm shadow-lg border-primary hover:bg-primary hover:text-white"
    >
      <div className="flex justify-between">
        <b className="flex-1 whitespace-nowrap">Subject Code</b>{" "}
        <span className="text-left flex-1">{note.subcode}</span>
      </div>
      <div className="flex justify-between my-3">
        <b className="flex-1">Unit No.</b>{" "}
        <span className="text-left flex-1">{note.unitno}</span>
      </div>
      {note.test_date && (
        <div className="flex justify-between my-3">
          <b className="flex-1">Conduction Date</b>{" "}
          <span className="text-left flex-1">{note.test_date}</span>
        </div>
      )}
      <hr className="" />
      <div className="py-3 flex justify-between px-2 items-center">
        <span className="text-inherit text-sm">{note.uploaded_date}</span>
        <a
          href={
            (note.test_date
              ? "http://jbportals.com/kspolytechnic/AT2021UT/"
              : "http://jbportals.com/kspolytechnic/AT1NOTES/") + note.filename
          }
          download
          target={"_blank"}
          rel={"noreferrer"}
        >
          <AiOutlineDownload className="text-[24px] text-inherit hover:scale-110 active:scale-95 cursor-pointer" />
        </a>
      </div>
    </motion.div>
  );
}

export default JBPCard;
