import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { AssignmentsSlice } from "./assignments.slice";
import { AuthSlice } from "./auth.slice";
import { CircularsSlice } from "./circulars.slice";
import { NotesSlice } from "./notes.slice";
import { UTSlice } from "./ut.slice";
import storageSession from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { RemarksSlice } from "./remarks.slice";
import { ScheduleSlice } from "./schedule.slice";
import { MarksSlice } from "./marks.slice";
import { TeachersSlice } from "./teachers.slice";
import { AttancdenceSlice } from "./attendence.slice";

const rootReducers = combineReducers({
  [AuthSlice.name]: AuthSlice.reducer,
  [NotesSlice.name]: NotesSlice.reducer,
  [UTSlice.name]: UTSlice.reducer,
  [AssignmentsSlice.name]: AssignmentsSlice.reducer,
  [CircularsSlice.name]: CircularsSlice.reducer,
  [RemarksSlice.name]: RemarksSlice.reducer,
  [ScheduleSlice.name]: ScheduleSlice.reducer,
  [MarksSlice.name]: MarksSlice.reducer,
  [TeachersSlice.name]: TeachersSlice.reducer,
  [AttancdenceSlice.name]: AttancdenceSlice.reducer,
});

const persistedReducer = persistReducer(
  { key: "root", storage: storageSession, whitelist: ["auth"] },
  rootReducers
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistore = persistStore(store);
