import React, { useState, useEffect } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { motion } from "framer-motion";
import JBButton from "../utils/JBButton";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { uploadAssignment } from "../../store/assignments.slice";

function Card({ assignment }) {
  const [buttonTitle, setButtonTitle] = useState("");
  const filtered = useSelector((state) => state.assignments.filtered);
  const fileref = useRef(null);
  const dispatch = useDispatch();
  //changes

  useEffect(() => {
    switch (assignment.status) {
      case "NOTUPLOAD":
        setButtonTitle("Upload");
        break;
      case "PENDING":
        setButtonTitle("Pending");
        break;
      case "APPROVED":
        setButtonTitle("Approved");
        break;
      case "DENIED":
        setButtonTitle("Re-Upload");
        break;
    }
  }, [filtered]);

  function onClickHandle() {
    fileref.current.click();
  }

  return (
    <motion.div
      layout
      animate={{ opacity: 1, y: 0, scale: 1 }}
      initial={{ opacity: 0, y: 20, scale: 0.7 }}
      transition={{ duration: 0.4, type: "spring" }}
      className="bg-white w-full h-full mt-4 pt-3 px-4 border rounded-md md:mt-0 text-sm shadow-lg border-primary"
    >
      <div className="flex justify-between">
        <b className="flex-1 whitespace-nowrap">Subject Code</b>
        <span className="text-left flex-1">{assignment.subcode}</span>
      </div>
      <div className="flex justify-between my-3">
        <b className="flex-1">Teacher</b>{" "}
        <span className="text-left flex-1">{assignment.sname}</span>
      </div>
      <div className="flex justify-between my-3">
        <b className="flex-1">Submission</b>{" "}
        <span className="text-left flex-1">{assignment.submission_date}</span>
      </div>
      {assignment.cDate && (
        <div className="flex justify-between my-3">
          <b className="flex-1">Conduction Date</b>{" "}
          <span className="text-left flex-1">{assignment.teacher}</span>
        </div>
      )}
      <hr className="" />
      <div className="pt-2 flex justify-between px-2 items-center">
        <span className="text-textSecondary text-sm">
          {assignment.uploaded_date}
        </span>
        <a
          href={
            "http://jbportals.com/kspolytechnic/AT2021ASSIGNMENT/" +
            assignment.filename
          }
          download
          target={"_blank"}
          rel={"noreferrer"}
        >
          <AiOutlineDownload className="text-[24px] text-primary hover:scale-110 active:scale-95 cursor-pointer" />
        </a>
      </div>
      <div className={"py-3 px-2"}>
        <input
          accept="application/pdf"
          className="hidden"
          type={"file"}
          ref={fileref}
          onChange={(e) => {
            dispatch(
              uploadAssignment({
                acadyear: "2021",
                subcode: assignment.subcode,
                unitno: assignment.unitno,
                myfile: e.target.files[0],
                cid: assignment.cid,
                sid: assignment?.id,
              })
            );
          }}
        />
        <JBButton onClick={onClickHandle} full status={assignment.status}>
          {buttonTitle}
        </JBButton>
      </div>
    </motion.div>
  );
}
export default Card;
