import React, { useEffect } from "react";
import JBPSelect from "../utils/JBPSelect";
import JBPCard from "../utils/JBPCard";
import { getNotes, getOptions, sortBySubCode } from "../../store/notes.slice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../utils/Loader";
import { motion } from "framer-motion";

function Notes() {
  const notesData = useSelector((state) => state.notes.filtered);
  const isPending = useSelector((state) => state.notes.pending);
  const options = useSelector((state) => state.notes.options);

  const dispatch = useDispatch();

  useEffect(() => {
    async function intializeData() {
      await dispatch(getNotes({ sem: 1, branch: "at" }));
      dispatch(getOptions());
    }
    intializeData();
  }, []);

  function sort(filterValue) {
    dispatch(sortBySubCode(filterValue));
  }

  return (
    <div className="px-primaryMob">
      <div>
        <JBPSelect
          onChange={(e) => {
            sort(e.target.value);
          }}
          options={["All", ...options]}
        />
      </div>
      {isPending && (
        <div className="flex justify-center items-center py-16">
          <Loader />
        </div>
      )}
      <motion.div
        layout
        className="pb-16 md:py-10 h-fit  md:grid  md:grid-cols-2 lg:grid-cols-3 gap-4"
      >
        {notesData.map((note, index) => {
          return <JBPCard {...{ note }} key={note.subcode + index} />;
        })}
      </motion.div>
    </div>
  );
}

export default Notes;
