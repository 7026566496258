import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import JBButton from "../utils/JBButton";
import JBPSelect from "../utils/JBPSelect";

function GR() {
  const [cat, setCat] = useState(undefined);
  const [feedback, setFeedback] = useState("");

  async function submitFeedback() {
    const formData = new FormData();
    formData.append("regno", "465at21012");
    formData.append("sem", 1);
    formData.append("name", "KAVANA.S");
    formData.append("branch", "AT");
    formData.append("category", cat);
    formData.append("feedback", feedback);
    const toastId = toast("Processing...", {
      autoClose: false,
      isLoading: true,
      position: "top-center",
    });

    const res = await axios.post(
      process.env.REACT_APP_API_URL + "feedback.php",
      formData,
      { method: "POST" }
    );
    const status = res.data.status;
    console.log(status);
    if (status) {
      toast.update(toastId, {
        type: "success",
        isLoading: false,
        autoClose: true,
        render: "Feedback sent successfully",
      });
      setFeedback("");
    } else {
      toast.update(toastId, {
        type: "error",
        isLoading: false,
        autoClose: true,
        render: "Something went wrong !",
      });
    }
  }
  return (
    <div className="p-primaryMob">
      <h1 className="text-xl font-bold">FeedBack/Grievance Redressal</h1>
      <JBPSelect
        className={"mt-10"}
        placeholder={"Select Category"}
        title={"Category"}
        options={[
          "Disciplinary Feedback",
          "Academic Feedback",
          "Complaint On Staff",
          "Complaint On Infrastructure",
          "Others",
        ]}
        value={cat}
        onChange={(e) => setCat(e.target.value)}
      />
      <div className="w-full flex flex-col mt-10 lg:px-primaryMob">
        <label className="font-bold text-md">
          Feedback(Upto 160 charcters)
        </label>
        <textarea
          className="resize-none border w-full md:w-[400px] border-textSecondary outline-primary mt-2 rounded-md py-2 px-2"
          cols={4}
          rows={5}
          maxLength={160}
          placeholder="Type here..."
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        ></textarea>
      </div>
      <div className="mt-3 lg:px-primaryMob">
        <JBButton disabled={!cat || !feedback} onClick={submitFeedback}>
          Submit Feedback
        </JBButton>
      </div>
    </div>
  );
}

export default GR;
