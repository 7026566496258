import React from "react";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { useSelector } from "react-redux";

const Hero = () => {
  const monthAttendence = useSelector((state) => state.attendence.data);
  const isError = useSelector((state) => state.attendence.error);
  const dayAttendence = useSelector((state) => state.attendence.dataDay);

  if (isError)
    return (
      <h1 className="w-full text-center py-10 font-bold">{isError.msg}</h1>
    );
  else if (monthAttendence.length == 0 && dayAttendence.length == 0)
    return null;
  return (
    <div className=" p-primaryMob">
      <div className="overflow-x-auto customScrollLight relative  shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            {dayAttendence.length == 0 ? (
              <tr>
                <th scope="col" className="py-3 text-center text-sm">
                  Subject Name
                </th>
                <th scope="col" className="py-3 text-center text-sm">
                  Attended Classes
                </th>
                <th scope="col" className="py-3 text-center text-sm">
                  Total Classes
                </th>
                <th scope="col" className="py-3 text-center text-sm">
                  Percentage
                </th>
              </tr>
            ) : (
              <tr>
                {dayAttendence.map((dayatt, index) => {
                  return (
                    <th
                      key={index}
                      scope="col"
                      className="py-3 text-center text-sm"
                    >
                      {Object.keys(dayatt)[0]}
                    </th>
                  );
                })}
              </tr>
            )}
          </thead>
          {dayAttendence.length == 0 ? (
            <tbody>
              {monthAttendence.map((attnedence, index) => {
                const perce = Math.floor(
                  (attnedence.attended / attnedence.totalclasses) * 100
                );
                if (attnedence.attended !== null)
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                    >
                      <td className="py-4 px-6">{attnedence.subname}</td>
                      <td className="py-4 px-6">{attnedence.attended}</td>
                      <td className="py-4 px-6">{attnedence.totalclasses}</td>
                      <td className=" w-[100px]">
                        <div
                          className={`py-4 px-6 h-full w-[${perce}%] bg-primary text-white font-bold`}
                        >
                          {perce}%
                        </div>
                      </td>
                    </tr>
                  );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                {dayAttendence.map((attnedence, index) => {
                  return (
                    <td className="py-4 px-6" key={index}>
                      {Object.values(attnedence)[0] == "P" ? (
                        <div className="text-green-500 text-center flex gap-2 text-md  font-bold items-center">
                          Present <AiOutlineCheckCircle />
                        </div>
                      ) : (
                        <div className="text-red-500 text-center flex gap-2 text-md  font-bold items-center">
                          Absent <AiOutlineCloseCircle />
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default Hero;
