import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getNotes = createAsyncThunk(
  "/notes/get",
  async (_payload, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const formData = new FormData();
      const { sem, branch } = getState().auth.user;
      formData.append("sem", sem);
      formData.append("branch", branch);
      const response = await axios({
        url: process.env.REACT_APP_API_URL + "notes.php",
        method: "POST",
        data: formData,
      });
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue({ msg: "something went wrong !" });
    }
  }
);

export const NotesSlice = createSlice({
  name: "notes",
  reducers: {
    getOptions: (state) => {
      const options = state.data.map((note) => note.subcode);
      state.options = options.filter(
        (subcode, index) => options.indexOf(subcode) === index
      );
    },
    sortBySubCode: (state, action) => {
      if (action.payload.toUpperCase() === "ALL") state.filtered = state.data;
      else
        state.filtered = state.data.filter(
          (note) => note.subcode === action.payload
        );
    },
  },
  initialState: {
    data: [],
    pending: false,
    error: null,
    options: [],
    filtered: [],
  },
  extraReducers: {
    [getNotes.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.filtered = action.payload;
      state.pending = false;
    },
    [getNotes.pending]: (state) => {
      state.pending = true;
    },
    [getNotes.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { getOptions, sortBySubCode } = NotesSlice.actions;
